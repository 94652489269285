import { FC, useState, useCallback, useEffect } from 'react'
import { isEmpty } from 'ramda'

import BaseDialog from '~/src/components/shared/BaseDialog'

import useBugReportQuery from '~/src/components/ReportIssue/hook'

import CreateTaskStep from '~/src/components/ReportIssue/CreateTaskStep'

import { Option, Tag } from '~/src/components/ReportIssue/types'

const OPTIONS: Array<Option> = [
  {
    value: 'Select an option',
    tag: Tag.BUG,
  },
  { value: 'Report a bug', tag: Tag.BUG },
  { value: 'Request a feature', tag: Tag.FEATURE },
  { value: 'Feedback', tag: Tag.FEEDBACK },
]

type Props = {
  isOpen: boolean
  isV2: boolean
  onCloseHandler: () => void
}

const ReportIssue: FC<Props> = ({ isOpen, isV2, onCloseHandler }) => {
  const [details, setDetails] = useState('')
  const [subject, setTitle] = useState('')
  const [selectedOption, setSelectedOption] = useState<Option>(
    isV2 ? OPTIONS[2] : OPTIONS[0]
  )
  const [validationError, setValidationError] = useState('')

  const [submitBugReportHandler, { isLoading, status, reset }] = useBugReportQuery()

  useEffect(() => {
    if (status === 'success') {
      onCloseHandler()
    }
  }, [status, onCloseHandler])

  const resetState = useCallback(() => {
    setTitle('')
    setDetails('')
    setSelectedOption(OPTIONS[0])
    setValidationError('')
    reset()
  }, [reset])

  const submitBugReport = useCallback(() => {
    if (isEmpty(subject)) {
      setValidationError('Title is required')

      return
    }

    submitBugReportHandler({
      subject,
      details,
      tag: selectedOption.tag || Tag.BUG,
    })
  }, [subject, details, selectedOption, submitBugReportHandler])

  const onCloseDialog = useCallback(() => {
    resetState()
    onCloseHandler()
  }, [resetState, onCloseHandler])

  const onOptionChange = useCallback(
    (option: Option) => {
      setSelectedOption(option)
    },
    [setSelectedOption]
  )

  const handleSubjectChange = (value: string) => {
    setTitle(value)
    setValidationError('')
  }

  return (
    <BaseDialog
      isOpen={isOpen}
      title={isV2 ? 'Provide feedback' : 'Report an issue'}
      loading={isLoading}
      className="w-[25rem]"
      primaryButtonText="Submit"
      onClose={onCloseDialog}
      onConfirm={submitBugReport}
    >
      <CreateTaskStep
        options={OPTIONS.slice(1, OPTIONS.length)}
        selectedOption={selectedOption}
        onOptionChange={onOptionChange}
        onDetailsChanged={setDetails}
        onSubjectChanged={handleSubjectChange}
        isError={status === 'error'}
        isV2
        validationError={validationError}
      />
    </BaseDialog>
  )
}

export default ReportIssue
