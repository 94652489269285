import { useEffect, useRef } from 'react'

import { MixpanelEvent, track } from '~/clients/mixpanelClient'

type RegisteredKeyDetails = {
  keys: string
  ctx?: string
  description: string
  callback: () => void
}

let context = ''
let registeredKeys: Array<RegisteredKeyDetails> = []

const setHotKeysContext = (newContext: string): void => {
  context = newContext
}

const useHotKeys = (
  keys: string,
  onKey: () => void,
  opts?: {
    description?: string
    ctx?: string
  }
): void => {
  const cbRef = useRef(onKey)
  cbRef.current = onKey

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent): void => {
      const target = e.target as HTMLElement
      if (['INPUT', 'TEXTAREA'].includes(target.tagName) || target.tabIndex >= 0) {
        return
      }

      if (e.ctrlKey || e.altKey || e.shiftKey || e.metaKey) {
        // Dont handle modifer keys for now
        return
      }

      if (opts?.ctx && opts?.ctx !== context) return

      if (keys.split(', ').includes(e.key.toLowerCase())) {
        cbRef.current()
        track(MixpanelEvent.HotkeyUsed, { key: e.key, context: opts?.ctx })
      }
    }

    window.addEventListener('keydown', keydownHandler, true)

    const description = opts?.description ?? cbRef.current.toString()
    registeredKeys.push({ keys, ctx: opts?.ctx, description, callback: cbRef.current })

    return (): void => {
      window.removeEventListener('keydown', keydownHandler, true)
      registeredKeys = registeredKeys.filter(
        (v) => v.keys !== keys || v.ctx !== opts?.ctx || v.description !== description
      )
    }
  }, [opts?.description, opts?.ctx, keys])
}

const getActiveKeys = (): Array<RegisteredKeyDetails> =>
  registeredKeys.filter(({ ctx }) => !ctx || ctx === context)

export { setHotKeysContext, getActiveKeys }
export default useHotKeys
