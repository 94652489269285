import { useCallback, useEffect } from 'react'

import { MixpanelEvent, track, timeEvent } from '~/clients/mixpanelClient'

export const useScreenTimeTracking = () => {
  // Using session storage instead of state/ref hooks so that the state is persisted
  // across page reloads.
  const statusKey = 'mixpanel-screen-time-session-status'
  const reloadKey = 'mixpanel-screen-time-tracking-reload'

  const startSession = useCallback(() => {
    const currentStatus = sessionStorage.getItem(statusKey)

    if (!currentStatus) {
      track(MixpanelEvent.SessionStart)
      timeEvent(MixpanelEvent.ActiveScreenTime)
      sessionStorage.setItem(statusKey, 'active')
    }
  }, [])

  const endSession = useCallback(() => {
    const currentStatus = sessionStorage.getItem(statusKey)

    if (currentStatus) {
      currentStatus === 'active' && track(MixpanelEvent.ActiveScreenTime)
      currentStatus === 'passive' && track(MixpanelEvent.PassiveScreenTime)
      track(MixpanelEvent.SessionEnd)
      sessionStorage.removeItem(statusKey)
    }
  }, [])

  const handleVisibilityChange = useCallback(() => {
    // The `visibilityChange` event get's called after a `pagehide` event if the page is
    // reloaded. We ignore `visibilityChange` if it resulted from a reload otherwise it
    // will make trigger a momentary state transition from active --> passive then back
    // passive --> active as the page is hidden then becomes visible during a reload.
    if (sessionStorage.getItem(reloadKey)) {
      sessionStorage.removeItem(reloadKey)

      return
    }

    const currentStatus = sessionStorage.getItem(statusKey)
    if (currentStatus === 'active' && document.visibilityState === 'hidden') {
      track(MixpanelEvent.ActiveScreenTime)
      timeEvent(MixpanelEvent.PassiveScreenTime)
      sessionStorage.setItem(statusKey, 'passive')
    } else if (currentStatus === 'passive' && document.visibilityState === 'visible') {
      track(MixpanelEvent.PassiveScreenTime)
      timeEvent(MixpanelEvent.ActiveScreenTime)
      sessionStorage.setItem(statusKey, 'active')
    }
  }, [])

  const handlePageHide = useCallback(() => {
    const isReload =
      (performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming)
        .type === 'reload'

    // exact value stored for `reloadKey` doesn't matter as long as it's truthy
    isReload ? sessionStorage.setItem(reloadKey, 'true') : endSession()
  }, [endSession])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, true)
    window.addEventListener('pagehide', handlePageHide, true)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange, true)
      window.removeEventListener('pagehide', handlePageHide, true)
    }
  }, [handleVisibilityChange, handlePageHide])

  return { startSession, endSession }
}
